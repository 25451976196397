import printReportAction from '@/components/nsw-components/PrintReportsAction';
import DocumentNewVersionAction from '@/components/nsw-components/DocumentNewVersionAction';

export default {
	actions: [
		printReportAction.printReport('GENERAL_DECLARATION', 'imofalformid', 'reports.label.falForms.generalDeclaration'),
		DocumentNewVersionAction.newVersion({
			imofalformtypeid: 1
		})
	]
};
