<template>
	<div class="pui-form">
		<pui-form-header :showHeader="!isCreatingElement" v-if="modelLoaded">
			<imofalformsform-header v-model="model.imofalforms"></imofalformsform-header>
		</pui-form-header>
		<doc-new-version-modal :modelName="modelName" :pkAttribute="pkAttribute"></doc-new-version-modal>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-tabs v-model="tabmodel" class="ml-3 mb-3" slider-color="primary">
				<v-tab :key="0" :href="'#imofalform1'">{{ $t('imofalform1.maintab') }}</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tabmodel">
				<v-tab-item :key="0" lazy :value="'imofalform1'">
					<v-row class="pui-form-layout">
						<v-col xs12 lg12>
							<v-row dense>
								<v-col class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
									<pui-select
										:id="`imofalform1-portcall`"
										attach="imofalform1-portcall"
										:label="$t('imofalform1.portcall')"
										toplabel
										clearable
										:disabled="!this.isCreatingElement"
										v-model="model.imofalforms"
										modelName="vlupportcalls"
										:modelFormMapping="{ portcallid: 'portcallid' }"
										:itemsToSelect="[{ portcallid: model.imofalforms.portcallid }]"
										:itemValue="['portcallid']"
										:itemText="(item) => `${item.visitid}`"
										:order="{ portcallid: 'desc' }"
										:fixedFilter="filterPortcalls"
									></pui-select>
								</v-col>
								<v-col class="col-6 col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2" style="padding-top: 20px; margin-left: 50px">
									<pui-checkbox
										:id="`imofalform1-isarrival`"
										:label="$t('imofalform1.isarrival')"
										v-model="model.imofalforms.isarrival"
										:disabled="!this.isCreatingElement"
										true-value="1"
										false-value="0"
									></pui-checkbox>
								</v-col>
								<v-col class="col-6 col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2" style="padding-top: 20px; margin-left: -50px">
									<pui-checkbox
										:id="`imofalform1-isdeparture`"
										:label="$t('imofalform1.isdeparture')"
										v-model="model.imofalforms.isdeparture"
										:disabled="!this.isCreatingElement"
										true-value="1"
										false-value="0"
									></pui-checkbox>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<pui-field-set :title="$t('menufieldset.ship')">
										<v-row dense>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
												<pui-text-field
													:id="`imofalform1-shipname`"
													v-model="model.imofalforms.portcall.mship.shipname"
													:label="$t('imofalform1.shipname')"
													disabled
													toplabel
												></pui-text-field>
											</v-col>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
												<pui-select
													id="`imofalform1-shiptype`"
													attach="imofalform1-shiptype"
													:label="$t('imofalform1.shiptype')"
													toplabel
													clearable
													disabled
													reactive
													v-model="model.imofalforms.portcall.mship"
													modelName="mshiptypes"
													:modelFormMapping="{ shiptypecode: 'shiptypecode' }"
													:itemsToSelect="itemsToSelectShipType"
													:itemValue="['shiptypecode']"
													:itemText="(item) => `${item.shiptypedesc}`"
													:order="{ shiptypedesc: 'asc' }"
												></pui-select>
											</v-col>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2">
												<pui-text-field
													:id="`imofalform1-imo`"
													v-model="model.imofalforms.portcall.mship.imo"
													:label="$t('imofalform1.imo')"
													disabled
													toplabel
												></pui-text-field>
											</v-col>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-1 col-xl-1">
												<pui-text-field
													:id="`imofalform1-callsign`"
													v-model="model.imofalforms.portcall.mship.callsign"
													:label="$t('imofalform1.callsign')"
													disabled
													toplabel
												></pui-text-field>
											</v-col>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-select
													id="`imofalform1-flag`"
													attach="imofalform1-flag"
													:label="$t('imofalform1.flag')"
													toplabel
													disabled
													clearable
													reactive
													v-model="model.imofalforms.portcall"
													modelName="mcountries"
													:modelFormMapping="{ countrycode: 'countrycode' }"
													:itemsToSelect="itemsToSelectFlag"
													:itemValue="['countrycode']"
													:itemText="(item) => `${item.countrydesc}`"
													:order="{ countrydesc: 'asc' }"
												></pui-select>
											</v-col>
										</v-row>
										<v-row dense style="margin-top: -15px">
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-select
													id="`imofalform1-registryport`"
													attach="imofalform1-registryport"
													:label="$t('imofalform1.registryport')"
													toplabel
													disabled
													reactive
													clearable
													v-model="model.imofalforms.portcall.mship"
													modelName="mports"
													:modelFormMapping="{ countrycode: 'certregistrycountry', portcode: 'certregistryport' }"
													:itemsToSelect="itemsToSelectRegistryPort"
													:itemValue="['countrycode', 'portcode']"
													:itemText="(item) => `${item.locode} - ${item.portdescription}`"
													:order="{ locode: 'asc' }"
												></pui-select>
											</v-col>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-date-field
													:id="`imofalform1-certregissueddate`"
													:label="$t('imofalform1.issuedate')"
													toplabel
													disabled
													v-model="model.imofalforms.portcall.mship.certregistrydate"
												></pui-date-field>
											</v-col>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-text-field
													:id="`imofalform1-certificatenum`"
													v-model="model.imofalforms.portcall.mship.certregistrynumber"
													:label="$t('imofalform1.certificatenum')"
													toplabel
													disabled
												></pui-text-field>
											</v-col>
										</v-row>
										<v-row dense style="margin-top: -15px">
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-number-field
													:id="`imofalform1-grosstonnage`"
													v-model="model.imofalforms.portcall.mship.grosstonnage"
													:label="$t('imofalform1.grosstonnage')"
													toplabel
													disabled
												></pui-number-field>
											</v-col>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-number-field
													:id="`imofalform1-nettonnage`"
													disabled
													v-model="model.imofalforms.portcall.mship.nettonnage"
													:label="$t('imofalform1.nettonnage')"
													toplabel
												></pui-number-field>
											</v-col>
										</v-row>
										<v-row dense style="margin-top: -15px">
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
												<pui-text-area
													:id="`imofalform1-wasterequirements`"
													v-model="model.wasterequirements"
													:label="$t('imofalform1.wasterequirements')"
													:disabled="formDisabled"
													toplabel
													maxlength="256"
												></pui-text-area>
											</v-col>
										</v-row>
									</pui-field-set>
								</v-col>
							</v-row>
							<v-row dense style="margin-top: -15px">
								<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<pui-field-set :title="$t('menufieldset.voyage')">
										<v-row dense>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-text-field
													:id="`imofalform1-voyagenum`"
													v-model="model.imofalforms.portcall.voyagenum"
													:label="$t('imofalform1.voyagenum')"
													disabled
													toplabel
												></pui-text-field>
											</v-col>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-select
													id="`imofalform1-portofarrival`"
													attach="imofalform1-portofarrival"
													:label="this.labelportarrival"
													toplabel
													v-model="model.imofalforms.portcall"
													modelName="mports"
													disabled
													reactive
													:modelFormMapping="{ countrycode: 'countrycode', portcode: 'portcode' }"
													:itemsToSelect="itemsToSelectArrivalPort"
													:itemValue="['countrycode', 'portcode']"
													:itemText="(item) => `${item.locode} - ${item.portdescription}`"
													:order="{ locode: 'asc' }"
												></pui-select>
											</v-col>
											<v-col
												v-if="this.model.imofalforms.isarrival === '1'"
												class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
											>
												<pui-date-field
													:id="`imofalform1-arrivaldate`"
													:label="$t('imofalform1.arrivaldate')"
													toplabel
													time
													v-model="model.arrivaldate"
													disabled
												></pui-date-field>
											</v-col>
											<v-col
												v-if="this.model.imofalforms.isdeparture === '1'"
												class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
											>
												<pui-date-field
													:id="`imofalform1-departuredate`"
													:label="$t('imofalform1.departuredate')"
													toplabel
													time
													v-model="model.departuredate"
													disabled
												></pui-date-field>
											</v-col>
											<v-col
												v-if="this.model.imofalforms.isarrival === '1'"
												class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
											>
												<pui-select
													id="`imofalform1-lastport`"
													attach="imofalform1-lastport"
													:label="$t('imofalform1.lastport')"
													toplabel
													reactive
													disabled
													v-model="model.imofalforms.portcall"
													modelName="mports"
													:modelFormMapping="{ countrycode: 'previouscountry', portcode: 'previousport' }"
													:itemsToSelect="itemToSelectPreviousPort"
													:itemValue="['countrycode', 'portcode']"
													:itemText="(item) => `${item.locode} - ${item.portdescription}`"
													:order="{ locode: 'asc' }"
												></pui-select>
											</v-col>
											<v-col
												v-if="this.model.imofalforms.isdeparture === '1'"
												class="col-12 col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3"
											>
												<pui-select
													id="`imofalform1-nextport`"
													attach="imofalform1-nextport"
													:label="$t('imofalform1.nextport')"
													toplabel
													reactive
													v-model="model.imofalforms.portcall"
													disabled
													modelName="mports"
													:modelFormMapping="{ countrycode: 'nextcountry', portcode: 'nextport' }"
													:itemsToSelect="itemToSelectNextPort"
													:itemValue="['countrycode', 'portcode']"
													:itemText="(item) => `${item.locode} - ${item.portdescription}`"
													:order="{ locode: 'asc' }"
												></pui-select>
											</v-col>
										</v-row>
										<v-row dense style="margin-top: -15px">
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-text-field
													:id="`imofalform1-mastername`"
													v-model="model.imofalforms.portcall.mastername"
													:label="$t('imofalform1.mastername')"
													:disabled="formDisabled || portCallDisableMasterName"
													toplabel
												></pui-text-field>
											</v-col>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-number-field
													:id="`imofalform1-numcrew`"
													v-model="model.numcrew"
													required
													:disabled="formDisabled"
													:label="$t('imofalform1.numcrew')"
													toplabel
												></pui-number-field>
											</v-col>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-number-field
													:id="`imofalform1-numpassengers`"
													v-model="model.numpassengers"
													:label="$t('imofalform1.numpassengers')"
													toplabel
													:disabled="formDisabled"
													required
												></pui-number-field>
											</v-col>
										</v-row>
										<v-row dense style="margin-top: -15px">
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
												<pui-text-area
													:label="$t('imofalform1.voyageparticulars')"
													:disabled="formDisabled"
													v-model="model.voyageparticulars"
													toplabel
													maxlength="256"
												>
												</pui-text-area>
											</v-col>
										</v-row>
										<v-row dense style="margin-top: -15px">
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
												<pui-text-area
													:id="`imofalform1-cargodescription`"
													v-model="model.cargodescription"
													:label="$t('imofalform1.cargodescription')"
													:disabled="formDisabled"
													required
													toplabel
													maxlength="256"
												></pui-text-area>
											</v-col>
										</v-row>
										<v-row dense style="margin-top: -15px">
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
												<pui-text-area
													:id="`imofalform1-observations`"
													v-model="model.observations"
													:label="$t('imofalform1.observations')"
													:disabled="formDisabled"
													toplabel
													maxlength="256"
												></pui-text-area>
											</v-col>
										</v-row>
									</pui-field-set>
								</v-col>
							</v-row>
							<v-row dense style="margin-top: -15px">
								<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<pui-field-set :title="$t('imofalform1.fieldset.agent')">
										<v-row dense>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
												<pui-text-field
													:id="`imofalform1-contactdetails`"
													v-model="model.contactdetails"
													:label="$t('imofalform1.contactdetails')"
													disabled
													toplabel
													maxlength="500"
												></pui-text-field>
											</v-col>
										</v-row>
									</pui-field-set>
								</v-col>
							</v-row>
							<v-row dense style="margin-top: -15px" v-show="false">
								<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<pui-field-set :title="$t('imofalform1.fieldset.documents')">
										<v-row dense>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-2 col-xl-2">
												<pui-number-field
													:id="`imofalform1-cargodeclaration`"
													v-model="model.cargodeclaration"
													:label="$t('imofalform1.cargodeclaration')"
													toplabel
													:disabled="formDisabled"
													required
												></pui-number-field>
											</v-col>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-2 col-xl-2">
												<pui-number-field
													:id="`imofalform1-numstores`"
													v-model="model.numstores"
													:label="$t('imofalform1.numstores')"
													toplabel
													required
													:disabled="formDisabled"
												></pui-number-field>
											</v-col>

											<v-col class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-2 col-xl-2">
												<pui-number-field
													:id="`imofalform1-numcrewlist`"
													v-model="model.numcrewlist"
													:label="$t('imofalform1.numcrewlist')"
													toplabel
													required
													:disabled="formDisabled"
												></pui-number-field>
											</v-col>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-2 col-xl-2">
												<pui-number-field
													:id="`imofalform1-numpassengerlist`"
													v-model="model.numpassengerlist"
													:label="$t('imofalform1.numpassengerlist')"
													toplabel
													:disabled="formDisabled"
													required
												></pui-number-field>
											</v-col>

											<v-col
												class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-2 col-xl-2"
												v-if="this.model.imofalforms.isarrival === '1'"
											>
												<pui-number-field
													:id="`imofalform1-numeffects`"
													v-model="model.numeffects"
													:disabled="formDisabled"
													:label="$t('imofalform1.numeffects')"
													toplabel
												></pui-number-field>
											</v-col>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-2 col-xl-2">
												<pui-number-field
													:id="`imofalform1-nummdh`"
													v-model="model.nummdh"
													:disabled="formDisabled"
													:label="$t('imofalform1.nummdh')"
													toplabel
												></pui-number-field>
											</v-col>
										</v-row>
									</pui-field-set>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-tab-item>
			</v-tabs-items>
			<pui-form-footer v-if="!isModalDialog">
				<nsw-imo-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:value="model.imofalforms"
					:saveAndUpdate="saveAndUpdate"
					:showNext="false"
					:showDraft="this.model.status !== this.statusDraftDesc"
					:showSubmit="this.model.status !== this.statusDraftDesc"
					:showNewVersion="!isCreatingElement && this.model.status === this.statusSubmitted"
					modelName="imofalform1"
					:save="save"
					:back="back"
				></nsw-imo-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import { STATUS_DRAFT_DESC } from '../../constants/imofalforms/ImoFalFormsConstants';
import imofalform1Actions from '../imofalform1menu/ImoFalForm1Actions';
import docNewVersionModal from '@/components/nsw-components/DocumentNewVersionModal';

export default {
	name: 'imofalform1form',
	mixins: [PuiFormMethodsMixin],
	components: {
		DocNewVersionModal: docNewVersionModal
	},
	data() {
		return {
			modelName: 'imofalform1',
			actions: imofalform1Actions.actions,
			pkAttribute: 'imofalformid',
			modeldetail: true,
			tabmodel: 'imofalform1',
			labelportarrival: this.$t('imofalform1.portofarrival'),
			portCallDisableMasterName: false,
			portCallDisablePort: false,
			portCallDisableIssueDate: false,
			portCallDisableCertNum: false,
			statusDraftDesc: STATUS_DRAFT_DESC
		};
	},
	mounted() {
		this.$puiEvents.$on('onPuiSelect_selectedItems-imofalform1-portcall', (newVal) => {
			var lupaModel = newVal.model;

			if (lupaModel !== null) {
				this.fillPortCallFields(lupaModel);
			} else {
				this.clearPortCallFields(lupaModel);
			}
		});
	},
	computed: {
		filterPortcalls() {
			return {
				groups: [
					{
						groupOp: 'and',
						rules: [
							{ field: 'portcallstatus', op: 'ne', data: 0 },
							{ field: 'portcallstatus', op: 'ne', data: 6 }
						]
					}
				]
			};
		},
		itemsToSelectShipType() {
			return [{ shiptypecode: this.model.imofalforms.portcall.mship.shiptypecode }];
		},
		itemsToSelectArrivalPort() {
			return [{ countrycode: this.model.imofalforms.portcall.countrycode, portcode: this.model.imofalforms.portcall.portcode }];
		},
		itemsToSelectFlag() {
			return [{ countrycode: this.model.imofalforms.portcall.countrycode }];
		},
		itemToSelectPreviousPort() {
			return [{ countrycode: this.model.imofalforms.portcall.previouscountry, portcode: this.model.imofalforms.portcall.previousport }];
		},
		itemToSelectNextPort() {
			return [{ countrycode: this.model.imofalforms.portcall.nextcountry, portcode: this.model.imofalforms.portcall.nextport }];
		},
		itemsToSelectRegistryPort() {
			return [
				{
					countrycode: this.model.imofalforms.portcall.mship.certregistrycountry,
					portcode: this.model.imofalforms.portcall.mship.certregistryport
				}
			];
		}
	},

	watch: {
		'model.imofalforms.isarrival'() {
			if (this.model.imofalforms.isarrival === '1') {
				this.model.imofalforms.isdeparture = '0';
				this.model.imofalforms.indarrivaldeparture = '1';
				this.labelportarrival = this.$t('imofalform1.portofarrival');
			}
		},

		'model.imofalforms.isdeparture'() {
			if (this.model.imofalforms.isdeparture === '1') {
				this.model.imofalforms.isarrival = '0';
				this.model.imofalforms.indarrivaldeparture = '2';
				this.labelportarrival = this.$t('imofalform1.portofdeparture');
			}
		}
	},
	methods: {
		fillPortCallFields(lupaModel) {
			this.model.imofalforms.portcall.mship.shipname = lupaModel.shipname;
			this.model.imofalforms.portcall.mship.shiptypecode = lupaModel.shiptypecode;
			this.model.imofalforms.portcall.mship.imo = lupaModel.imo;
			this.model.imofalforms.portcall.mship.callsign = lupaModel.callsign;
			this.model.imofalforms.portcall.mship.shipid = lupaModel.shipid;
			this.model.imofalforms.portcall.mship.version = lupaModel.version;
			this.model.imofalforms.portcall.voyagenum = lupaModel.voyagenum;
			this.model.imofalforms.portcall.countrycode = lupaModel.countrycode;
			this.model.imofalforms.portcall.portcode = lupaModel.portcode;
			this.model.arrivaldate = lupaModel.ata !== null ? lupaModel.ata : lupaModel.eta;
			this.model.departuredate = lupaModel.atd !== null ? lupaModel.atd : lupaModel.etd;
			this.model.imofalforms.portcall.countrycode = lupaModel.countrycode;
			this.model.imofalforms.portcall.mastername = lupaModel.mastername;
			this.model.imofalforms.portcall.mship.certregistrycountry = lupaModel.certregistrycountry;
			this.model.imofalforms.portcall.mship.certregistryport = lupaModel.certregistryport;
			this.model.imofalforms.portcall.mship.certregistrydate = lupaModel.certregistrydate;
			this.model.imofalforms.portcall.mship.certregistrynumber = lupaModel.certregistrynumber;
			this.model.imofalforms.portcall.mship.grosstonnage = lupaModel.grosstonnage;
			this.model.imofalforms.portcall.mship.nettonnage = lupaModel.nettonnage;
			this.model.imofalforms.portcall.previouscountry = lupaModel.previouscountry;
			this.model.imofalforms.portcall.previousport = lupaModel.previousport;
			this.model.imofalforms.portcall.nextcountry = lupaModel.nextcountry;
			this.model.imofalforms.portcall.nextport = lupaModel.nextport;
			this.model.imofalforms.portcall.mship.registryport = lupaModel.registryport;
			this.disableEnablePortCallsFields(lupaModel);
		},
		clearPortCallFields(lupaModel) {
			this.model.imofalforms.portcall.mship.shipname = null;
			this.model.imofalforms.portcall.mship.shiptypecode = null;
			this.model.imofalforms.portcall.mship.imo = null;
			this.model.imofalforms.portcall.mship.callsign = null;
			this.model.imofalforms.portcall.mship.shipversion = null;
			this.model.imofalforms.portcall.voyagenum = null;
			this.model.imofalforms.portcall.countryofarrival = null;
			this.model.imofalforms.portcall.countryofdeparture = null;
			this.model.imofalforms.portcall.portofarrival = null;
			this.model.imofalforms.portcall.portofdeparture = null;
			this.model.arrivaldate = null;
			this.model.departuredate = null;
			this.model.imofalforms.portcall.countrycode = null;
			this.model.imofalforms.portcall.portcode = null;
			this.model.imofalforms.portcall.mastername = null;
			this.model.imofalforms.portcall.mship.certregcountrycode = null;
			this.model.imofalforms.portcall.mship.certregportcode = null;
			this.model.imofalforms.portcall.mship.certregissueddate = null;
			this.model.imofalforms.portcall.mship.certregnum = null;
			this.model.imofalforms.portcall.mship.grosstonnage = null;
			this.model.imofalforms.portcall.mship.nettonnage = null;
			this.model.imofalforms.portcall.previouscountry = null;
			this.model.imofalforms.portcall.previousport = null;
			this.model.imofalforms.portcall.nextcountry = null;
			this.model.imofalforms.portcall.nextport = null;
			this.model.imofalforms.portcall.mship.registryport = null;
			this.disableEnablePortCallsFields(lupaModel);
		},
		disableEnablePortCallsFields(lupaModel) {
			if (lupaModel === null) {
				this.portCallDisableMasterName = false;
				this.portCallDisablePort = false;
				this.portCallDisableIssueDate = false;
				this.portCallDisableCertNum = false;
			} else {
				this.portCallDisableMasterName = lupaModel.mastername === null ? false : true;
				this.portCallDisablePort = lupaModel.certregcountrycode === null || lupaModel.certregportcode === null ? false : true;
				this.portCallDisableIssueDate = lupaModel.certregissueddate === null || lupaModel.certregissueddate === undefined ? false : true;
				this.portCallDisableCertNum = lupaModel.certregnum === null || lupaModel.certregnum === undefined ? false : true;
			}
		}
	},
	afterGetData() {},
	destroyed() {
		this.$puiEvents.$off('onPuiSelect_selectedItems-imofalform1-portcall');
	}
};
</script>
